/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/**
 * Profile photo component.
 */
class ProfilePhoto extends Component {
  render () {
    return (
      <StaticQuery query={graphql`
        query{
          placeholderImage: file(relativePath: {eq: "profile.jpg"}) {
            childImageSharp{
              fluid{
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data =>
        <Img
          fluid={data.placeholderImage.childImageSharp.fluid}
          className={`img-fluid rounded`}
        />
      }
      />
    )
  }
}

export default ProfilePhoto
