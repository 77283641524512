/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */

import React, { Component } from 'react'
import DefaultLayout from './../layouts/Default'
import SEO from './../components/Seo'
import { StaticQuery, graphql, Link } from 'gatsby'
import Icon from './../components/Icon'
import Stack from './../components/Stack'
import ProfilePhoto from './../components/Images/Profile'
import config from './../data/SiteConfig'

/**
 * About page.
 */
class AboutPage extends Component {
  render () {
    return (
      <DefaultLayout className={`page-about`}>
        <SEO
          pageType={`webpage`}
          pageTitle={`About`}
          fullPageTitle={`About Keith Mifsud`}
          pageDescription={`Keith is an independent Software Engineer. Keith enjoys solving non-trivial business challenges with code.`}
          pageImage={config.siteImage}
          pagePath={`/about`}
        />
        <div className="container">
          <div className="row pt-lg-12 pt-8">
            <section className={`col-12`}>
              <h1>A wee bit about me ...</h1>
            </section>
          </div>
          <div className="row pt-10">
            <div className="col-lg-9 col-12">
              <p className="lead">I am an independent Software Engineer and I've been working in <Link to={`/services/full-stack/development`} title={`Software development`} className={`hover-darken`}>software development</Link> and <Link to={`/services/full-stack/development`} title={`Software services`} className={`hover-darken`}>related services</Link> for the past fourteen years.</p>
              <p>I enjoy solving non-trivial business challenges with code. I proudly adopt coding, architecture and workflow best practices. More importantly, I enjoy learning new things every day and make a point to do so. I have a mentor personality and I believe I'm obliged to share all I learn with others.</p>
              <p>I also believe that Software Engineers are able to work with and learn any programming language, architectures and teams of varying skills.</p>
              <div className="home-stack mt-lg-9 mt-5">
                <Stack/>
              </div>
              <div className="mt-lg-14   mb-lg-9 mb-5 mt-5">
                <h2>A few of my (other) favourite things ...</h2>
                <p className={`lead`}>
                  When I'm not programming, writing about it or teaching it, I enjoy cooking, taking the boys out on a bike ride and playing with the dog.
                </p>
                <p>I lead a Kanban style life and have meticulously adapted an ever-improving GTD system. I use GitHub projects for most of my work tasks and Google Keep for my personal life and ambitions. I think it is impossible for me to forget anything and I hope this remains unchanged in with my older self.</p>
              </div>
              <ProfilePhoto/>
            </div>
            <aside className="col-12 col-lg-3 text-center pt-lg-0 pt-5">
              <div
                className="sidebar-content border ml-lg-5 sticky-top sticky-offset-header rounded p-3"
              >
                <h3 className={`pb-8`}>Let's work together</h3>
                <StaticQuery query={graphql`
                   query {
                    allMarkdownRemark (
                      sort: {fields: [frontmatter___order], order: ASC}
                      filter: {
                        fields: {
                          collection: {eq: "services"}
                        }
                      }
                    ) {
                      edges {
                        node {
                          id
                          frontmatter{
                            title
                            path
                            icon
                            order
                          }
                        }
                      }
                    }
                   }
                `}
                render={data =>
                  <div className={`row text-center`}>
                    {data.allMarkdownRemark.edges.map(({ node }, index, arr) => (
                      <div key={node.id} style={{ display: 'inline-block' }} className={`col-12 pb-5`}>
                        <Link
                          to={node.frontmatter.path}
                          className={`hover-none hover-darken`}
                          style={{ display: 'inline' }}
                        >
                          <Icon type={node.frontmatter.icon} style={{ display: 'inline' }}/>
                          <span className="pl-3 hover-darken" style={{ display: 'inline' }}>
                            {node.frontmatter.title}
                          </span>
                        </Link>
                      </div>
                    ))}
                  </div>
                }
                />
                <div className="row">
                  <div className="col-12">
                    <Link
                      to={`/contact`}
                      title={`Hire a full-stack Developer`}
                      className={`btn btn-large btn-warning text-white`}
                    >
                      <strong>Get in touch</strong>
                    </Link>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </DefaultLayout>
    )
  }
}

export default AboutPage
